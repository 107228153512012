import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

const About = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {

  const outerClasses = classNames(
    'testimonial section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'testimonial-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const tilesClasses = classNames(
    'tiles-wrap',
    pushLeft && 'push-left'
  );

  const sectionHeader = {
    title: 'About Us',
    paragraph: [
    <br/>,
    "At Paramo Games, we work in a collaborative style environment where we give everyone on the team the chance to be part of the creative process."
  ],
    
  };
  const technologies = {
    paragraph: ["We are happy to work with these technologies."  ],
    
  };


  return (
    <section
/*       {...props}
 */      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-right" data-reveal-delay="200">
              <div className="tiles-item-inner" style={{justifyContent: 'center'}}>
                <p className="text-sm mb-0">
                We want to develop games that are fun and entertaining for everyone. We want that people feels identified with our games and can enjoy them as much as we do.       
                </p>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">OUR VISION</span>
{/*                   <span className="text-color-low"> </span>
                  <span className="testimonial-item-link">
                    <a href="#0">MISSION</a>
                  </span> */}
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner" style={{justifyContent: 'center'}}>
                <p className="text-sm mb-0">
                We want to continue making great games for many years to come and to become a leading company in the games industry and a referent in our country.
                </p>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">OUR MISSION</span>
{/*                   <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    <a href="#0">AppName</a>
                  </span> */}
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner" style={{justifyContent: 'center'}}>
                <div className="text-sm mb-0">
                  <ul>
                    <li>Innovation and doing new things every day.</li>
                    <li>Curiosity always exploring and learning.</li>
                    <li>Passion for all that what we do.</li>
                  </ul>
                </div>
                <div className="testimonial-item-footer text-xs mt-32 mb-0 has-top-divider">
                  <span className="testimonial-item-name text-color-high">OUR VALUES</span>
{/*                   <span className="text-color-low"> / </span>
                  <span className="testimonial-item-link">
                    <a href="#0">AppName</a>
                  </span> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={technologies} className="center-content" />
          <div className={tilesClasses} style={{background: 'white', alignItems: 'center', textAlign: '-webkit-center'}}>

            <div className="tiles-item reveal-from-right" data-reveal-delay="200" >
            <Image
                  src={require('./../../assets/images/unity.png')}
                  alt="Unity"
                  width={528}
                  height={396} />
            </div>

            <div className="tiles-item reveal-from-bottom">
            <Image
                  src={require('./../../assets/images/engine.png')}
                  alt="Unreal Engine"
                  width={528}
                  height={396} />
            </div>

            <div className="tiles-item reveal-from-bottom">
            <Image
                  src={require('./../../assets/images/gamemaker.png')}
                  alt="Game Maker"
                  width={528}
                  height={396} />
            </div>
            <div className="tiles-item reveal-from-bottom">
            <Image
                  src={require('./../../assets/images/web.png')}
                  alt="Web"
                  width={200}
                  height={200} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

About.propTypes = propTypes;
About.defaultProps = defaultProps;

export default About;