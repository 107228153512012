import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const Games = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );
   
  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Work in progress',
    paragraph: ['We are working harder to develop new games for you.']
  };

  return (
    <section
      // {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>
          <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <h3 className="mt-0 mb-12">
                  LLAMALANDIA              
                </h3>
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Winner Unidos GameJam 2022
                </div>
                <div className="m-0-paragraph">
                  <p className="m-0-paragraph-games">
                    Play as a llama trying to return home and support your lost family in the Bolivian altiplano.
                  </p>
                  <ul>
                    <li>Genre: Adventure</li>
                    <li>Mode: Singleplayer </li>
                    <li>Platform: PC</li>
                  </ul>
                  <div style={{textAlign: 'center', display: 'flex', justifyContent: 'space-evenly'}}>

                  <a target="_blank" rel="noopener noreferrer" href="https://store.steampowered.com/app/2470990/Llamalandia/" style={{background: '#5658DD', borderRadius:'12px'}}>
                    <img  style={{padding: '5% 10% 5% 10%'}}
                        src={require('./../../assets/images/Steam.png')}
                        alt=""
                        width={70}
                        height={70} />
                    </a>

                    <a target="_blank" rel="noopener noreferrer" href="https://paramo-games.itch.io/llamalandia" style={{background: '#5658DD', borderRadius:'12px'}}>
                    <img  style={{padding: '5% 10% 5% 10%'}}
                        src={require('./../../assets/images/play.png')}
                        alt=""
                        width={70}
                        height={70} />
                    </a>

                    <a target="_blank" rel="noopener noreferrer" href="https://youtu.be/G29JoWELESc" style={{background: '#5658DD', borderRadius:'12px'}}>
                    <img  style={{padding: '5% 10% 5% 10%'}}
                        src={require('./../../assets/images/youtube.png')}
                        alt=""
                        width={70}
                        height={70} />
                    </a>
                    
                 </div>
                    
                </div>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">

                <Image
                  className="has-shadow"
                  src={require('./../../assets/images/Llamalandia.jpg')}
                  alt="Llamalandia"
                  width={528}
                  height={396} />
              </div>
            </div>

          

           {/*  <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Graveyard Nightmares
                </div>
                <h3 className="mt-0 mb-12">
                  Graveyard Nightmares
                </h3>
                <p className="m-0-paragraph">
                  A man looking for a way to escape from a graveyard, that changes as it is explored based on the player's decisions.
                  <ul>
                    <li>Genre: Survival horror</li>
                    <li>Mode: Single-player</li>
                    <li>Platform: PC, PS5, Xbox Series S/X</li>
                  </ul>
                </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/graveyardNightmares.png')}
                  alt="Graveyard Nightmares"
                  width={528}
                  height={396} />
              </div>
            </div> */}

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <h3 className="mt-0 mb-12">
                  SPOOKY HIDE                
                </h3>
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Honorable Mention Spoopy Jam by Game Jolt 2021
                </div>
                <div className="m-0-paragraph">
                  <p className="m-0-paragraph-games">
                    A casual hide and seek multiplayer game, where you can pick between 
                    being a ghost hunter and finding all the ghosts before the time runs out or becoming
                    a ghost than will have to scape and hide on the different objects of the environment.
                  </p>
                  <ul>
                    <li>Genre: Party game</li>
                    <li>Mode: Multiplayer</li>
                    <li>Platform: PC</li>
                  </ul>

                  <div style={{textAlign: 'center', display: 'flex', justifyContent: 'space-evenly'}}>

                    <a target="_blank" rel="noopener noreferrer" href="https://paramo-games.itch.io/spooky-hide" style={{background: '#5658DD', borderRadius:'12px'}}>
                    <img  style={{padding: '5% 10% 5% 10%'}}
                        src={require('./../../assets/images/play.png')}
                        alt=""
                        width={70}
                        height={70} />
                    </a>

                    <a target="_blank" rel="noopener noreferrer" href="https://youtu.be/ZvzFpeS2lwo" style={{background: '#5658DD', borderRadius:'12px'}}>
                    <img  style={{padding: '5% 10% 5% 10%'}}
                        src={require('./../../assets/images/youtube.png')}
                        alt=""
                        width={70}
                        height={70} />
                    </a>
                 </div>

                </div>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  className="has-shadow"
                  src={require('./../../assets/images/SpookyHide.jpg')}
                  alt="Spooky Hide"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <h3 className="mt-0 mb-12">
                  COLORLESS
                </h3>
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Game Jam+ 2021 Finalist, Anjo Category Winner
                </div>
                <div className="m-0-paragraph">
                  <p className="m-0-paragraph-games">
                  A challenge to return the life and the color to a gray world that lost them.
                  </p>
                  <ul>
                    <li>Genre: Action/Adventure</li>
                    <li>Mode: Single-player</li>
                    <li>Platform: PC</li>
                  </ul>
                  <div style={{textAlign: 'center', display: 'flex', justifyContent: 'space-evenly'}}>

                    <a target="_blank" rel="noopener noreferrer" href="https://paramo-games.itch.io/colorless" style={{background: '#5658DD', borderRadius:'12px'}}>
                      <img  style={{padding: '5% 10% 5% 10%'}}
                        src={require('./../../assets/images/play.png')}
                        alt=""
                        width={70}
                        height={70} />
                    </a>

                    <a target="_blank" rel="noopener noreferrer" href="https://youtu.be/Vq8iLwJNvEA" style={{background: '#5658DD', borderRadius:'12px'}}>
                      <img  style={{padding: '5% 10% 5% 10%'}}
                        src={require('./../../assets/images/youtube.png')}
                        alt=""
                        width={70}
                        height={70} />
                    </a>
                  </div>
                </div>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  className="has-shadow"
                  src={require('./../../assets/images/Colorless.jpg')}
                  alt="Colorless"
                  width={528}
                  height={396} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

Games.propTypes = propTypes;
Games.defaultProps = defaultProps;

export default Games;