import React from 'react';
// import sections
import Principal from '../components/sections/Hero';
import Values from '../components/sections/Values';
//import Games from '../components/sections/FeaturesSplit';
//import About from '../components/sections/Testimonial';
//import Cta from '../components/sections/Cta';

const Home = () => {

  return (
    <>
      <Principal />
      <Values />
      {/* <FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" />
      <About topDivider />
      <Cta split /> */}
    </>
  );
}

export default Home;